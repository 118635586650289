<script setup>
import {onMounted, ref} from 'vue'
import {
  ElCard,
  ElTableColumn,
  ElTable,
  ElRow,
  ElCol,
  ElDatePicker,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElOption,
  ElSelect,
  ElButton
} from "element-plus";
import {buyApi, myListApi, searchListApi} from "@/service/index.js";
import dayjs from "dayjs";
import {useStore} from "vuex";
import {Delete, Search, TurnOff} from "@element-plus/icons-vue";

const store = useStore()

let searParams = ref({
  page_size: 50, //     每页显示数量  默认50
  begin_time: '', // 开始时间
  end_time: '', // 结束时间
  types: '', //  类型传DHL、USPS、做下拉选择
  start_country: '', //  发货地
  end_country: ' ', // 收货地
})
let pagination = ref({
  current: 1,
  pageSize: 50,
  total: 100,
  showSizeChanger: false,
})
let payInfo = ref({})
let tabBox = ref(null)
let oBox = ref(null)
let yHeight = ref(0)
let loading = ref(false)
let selectedRowKeys = ref([])
let dialogBuy = ref(false)
let multipleTableRef=ref(null)
const handleSelectionChange = (e,e2) => {
  console.log(e,e2)
  console.log('multipleTableRef',multipleTableRef.value.getSelectionRows())
}
const clearSelection=()=>{
  multipleTableRef.value.clearSelection()
}

const cdata = ref([]);
const getOrderList = () => {
  loading.value = true
  let t1 = searParams.value.begin_time ? new Date(searParams.value.begin_time).valueOf() : null
  let t2 = searParams.value.end_time ? new Date(searParams.value.end_time).valueOf() : null
  myListApi({...searParams.value, page: pagination.value.current, begin_time: t1, end_time: t2}).then(res => {
    if (res.data.code == 1) {
      cdata.value = res.data.data.data
      pagination.value.total = res.data.data.total
    } else {
      ElMessage({
        message: res.data.msg,
        type: 'warning',
      })
    }
    console.log('data', cdata.value)
  }).finally(() => {
    loading.value = false
  })
}

const pageChange = (p) => {
  pagination.value.current = p
  getOrderList()
}
const ok1 = (v) => {
  // searParams.value.begin_time = v.$d
}
const ok2 = (v) => {
  // searParams.value.end_time= v.$d
}
const reset = () => {
  searParams.value = {
    // page_size: 50, //     每页显示数量  默认50
    begin_time: '', // 开始时间
    end_time: '', // 结束时间
    types: '', //  类型传DHL、USPS、做下拉选择
    start_country: '', //  发货地
    end_country: ' ', // 收货地
  }
  pagination.value = {
    current: 1,
    pageSize: 50,
    total: 100
  }
  getOrderList()

}
const buy = (scope) => {
  console.log('scope',scope)
  loading.value = true
  if(scope){
    buyApi({ids: scope.row.id}).then(res => {
      if (res.data.code == 1) {
        selectedRowKeys.value = []
        payInfo.value = res.data.data
        dialogBuy.value = true
        getOrderList()
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'warning',
        })
      }
    }).finally(() => {
      store.dispatch('updateUserInfoStatus')
      loading.value = false
    })
    return
  }
  console.log('selectedRowKeys', multipleTableRef.value.getSelectionRows())
  let ids=multipleTableRef.value.getSelectionRows().map(item=>item.id).join(',')
  console.log('ids',ids)
  if(ids.length<=1){
    ElMessage({
      message: '请勾选单号',
      type: 'error',
    })
    loading.value = false
    return;
  }
  buyApi({ids: ids}).then(res => {
    if (res.data.code == 1) {
      selectedRowKeys.value = []
      payInfo.value = res.data.data
      dialogBuy.value = true
      getOrderList()
    } else {
      ElMessage({
        message: res.data.msg,
        type: 'warning',
      })
    }
  }).finally(() => {
    store.dispatch('updateUserInfoStatus')
    loading.value = false
  })
}
const copyToClipboard = () => {
  let text = payInfo.value.waybill_sn
  var textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.opacity = 0;
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  ElMessage({
    message: "复制成功",
    type: 'success',
  })
  dialogBuy.value = false
}
getOrderList()
</script>

<template>

  <div class="o-list-box" ref="oBox">
<!--    <el-row :gutter="20">-->
<!--      <el-form :model="searParams" class="search-box">-->
<!--        <el-col :span="6">-->
<!--          <div>-->
<!--            <el-form-item prop="types" label="类型">-->
<!--              <el-select-->
<!--                  ref="select"-->
<!--                  show-search-->
<!--                  allow-clear-->
<!--                  placeholder="请选择类型"-->
<!--                  v-model="searParams.types"-->

<!--              >-->
<!--                <el-option-->
<!--                    v-for="(item,index) in  ['DHL','USPS']"-->
<!--                    :key="item"-->
<!--                    :label="item"-->
<!--                    :value="item"-->
<!--                />-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="6">-->
<!--          <div>-->
<!--            <el-form-item label="发货地" prop="start_country">-->
<!--              <el-select-->
<!--                  ref="select"-->
<!--                  show-search-->
<!--                  placeholder="请选择发货地"-->
<!--                  allow-clear-->
<!--                  v-model="searParams.start_country"-->

<!--              >-->
<!--                <el-option v-for="(item,index) in  store.state.clist" :label="item" :value="item.split('&#45;&#45;').pop()">{{-->
<!--                    item-->
<!--                  }}-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="6">-->
<!--          <el-form-item label="收货地" prop="end_country">-->
<!--            <el-select-->
<!--                placeholder="请选择收货地"-->
<!--                ref="select"-->
<!--                show-search-->
<!--                allow-clear-->
<!--                v-model="searParams.end_country"-->

<!--            >-->
<!--              <el-option v-for="(item,index) in  store.state.clist" :label="item" :value="item.split('&#45;&#45;').pop()">{{-->
<!--                  item-->
<!--                }}-->
<!--              </el-option>-->
<!--            </el-select>-->

<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :span="6">-->
<!--          <el-form-item label="每页显示数量" class="search-item-box" prop="end_country">-->
<!--            <el-input v-model="searParams.page_size" type="=number"></el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :span="13">-->
<!--          <el-form-item label="时间" prop="begin_time">-->
<!--            <el-date-picker type="datetime" show-time placeholder="开始时间" v-model="searParams.begin_time" @ok="ok1"/>-->
<!--            <span style="margin: 0 5px">至</span>-->
<!--            <el-date-picker type="datetime" show-time placeholder="结束时间" v-model="searParams.end_time" @ok="ok2"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->


<!--        <div class="b" style="width: 100%;"  >-->
<!--          <el-button :icon="Delete" @click="clearSelection" type="warning" size="large" round>一键清空</el-button>-->
<!--&lt;!&ndash;          <el-button :icon="Delete" @click="buy(null)" type="success" round size="large">批量兑换</el-button>&ndash;&gt;-->
<!--          &lt;!&ndash;        <a-button style="margin:0 5px" @click="reset" icon="download">一键清空</a-button>&ndash;&gt;-->
<!--          <el-button :icon="TurnOff" @click="reset" type="info" round size="large">重置</el-button>-->
<!--          <el-button :icon="Search" @click="getOrderList" :loading="loading" type="primary" round size="large">搜索-->
<!--          </el-button>-->
<!--        </div>-->
<!--      </el-form>-->
<!--    </el-row>-->
    <el-affix :offset="0" direction="horizontal">
      <div class="tab-box" ref="tabBox">
        <el-table
            border
            ref="multipleTableRef"
            height="90vh"
            v-loading="loading"
            :data="cdata"
            style="width: 100%"
            current-row-key="id"
            @selection-change="handleSelectionChange"
        >
<!--          <el-table-column type="selection" width="55"/>-->
          <el-table-column label="快递单号" prop="waybill_sn" width="200" ></el-table-column>
          <el-table-column property="start_address" label="发货地" />
          <el-table-column property="end_address" label="收货地" />
<!--          <el-table-column property="types" label="类型" width="80" align="center">-->
<!--            <el-button type="warning">已上网</el-button>-->
<!--          </el-table-column>-->
<!--          <el-table-column property="st" label="单号状态" width="360">-->
<!--            <el-button type="primary">敦煌网,速卖通,亚马逊,eBay,独立站等跨境平台</el-button>-->
<!--          </el-table-column>-->
          <el-table-column property="online_str" label="上网时间" width="180" />
          <el-table-column property="delivery_date" label="预计送达日期" width="110"/>
          <el-table-column property="package_num" label="包裹数量" width="80"/>
          <el-table-column property="updatetime" label="兑换时间" width="180" >
            <template #default="scope" >
              {{ dayjs(scope.row.updatetime * 1000).format('YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
<!--          <el-table-column property="st" label="操作" width="100" fixed="right" >-->
<!--            <template #default="scope">-->
<!--              <el-button size="large" type="primary" @click="buy(scope)" :loading="loading">兑换</el-button>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
        <div class="p-box" style="width: 100%;height:7vh;display: flex;justify-content: center;align-items: center" >
          <el-pagination
              style="margin: 0 auto"
              ::page-size="searParams.page_size-0"
              layout="prev, pager, next"
              :total="pagination.total"
              @currentChange="pageChange"
          />
        </div>
      </div>
    </el-affix>
    <el-dialog
        v-model="dialogBuy"
        width="500"
        :show-close="false"
    >
      <template #default>
        <div class="icc-box" v-if="payInfo&&payInfo.waybill_sn">
          <img src="/success.png" class="icc" alt="">
          <h1>兑换成功!</h1>
          <p class="d">订单号：{{ payInfo.log.order_sn }}</p>
          <a-divider style="border-color: rgba(0,0,0,0.09)" dashed/>
          <div class="l-box">
            <div class="l">兑换日期</div>
            <div class="l">兑换数量</div>
          </div>
          <div class="l-box">
            <div class="n">{{ dayjs(payInfo.log.createtime * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            <div class="n">{{ payInfo.waybill_sn.length }}</div>
          </div>
          <div style="width: 5px;height: 30px"></div>

          <div class="l-box">
            <div class="l">兑换状态</div>
            <div class="l">支付价格</div>
          </div>
          <div class="l-box">
            <div class="n">
              <a-tag color="green" style="transform: scale(1.2)">成功</a-tag>
            </div>
            <div class="n">
              <a-tag color="green" style="transform: scale(1.2)">{{ payInfo.log.score }}</a-tag>
            </div>
          </div>
          <a-divider style="border-color: rgba(0,0,0,0.09)" dashed/>
          <h1>单号列表</h1>
          <a-textarea
              :value="payInfo.waybill_sn"
              :autosize="{minRows:5}"
          />
          <a-button type="primary" style="width: 80%;height:40px;transform: translateY(50px)" @click="copyToClipboard">
            复制单号
          </a-button>
        </div>
      </template>
    </el-dialog>
  </div>

</template>

<style  lang="less" scoped>
.o-list-box {
  display: flex;
//height: 100%;
  flex: 1;
  flex-direction: column;
}

.tab-box::-webkit-scrollbar {
  width: 0px;
  overflow-y: hidden;
}

.tab-box {
  width: 100%;
  flex: 1;
//overflow-y: scroll;
}

.search-box {
  display: flex;
  flex-wrap: wrap;

  .search-item-box {
    margin-right: 20px;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }
}

.b {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  margin-bottom: 10px;
}

.icc-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-80px);

  .icc {
    width: 100px;
    background: white;
    border-radius: 50%;
  }
}

.l-box {
  display: flex;
  justify-content: space-between;
  width: 90%;

  .l {
    color: rgba(0, 0, 0, 0.5);
  }

  .n {
    color: black;
    font-size: 18px;
    margin-top: 8px;
  }
}
/deep/.el-select__wrapper{
  min-height: 40px !important;
}
/deep/thead{
  color: rgb(61, 118, 206) !important;


}
/deep/th{
  background: rgb(235, 243, 255) !important;
}
</style>