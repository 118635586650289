<template>
  <router-view/>
</template>

<style lang="less">
body{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 ;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  overflow-y: scroll;
  background: linear-gradient(rgba(22, 119, 255, 0.12), #f5f7fa);
  &::-webkit-scrollbar{
    width: 0;
  }
}
.cell{
  font-size: 12px;
}
</style>
