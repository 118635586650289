import { createStore } from 'vuex'
import {getUserInfoApi} from "@/service";
import {ElMessage} from "element-plus";

export default createStore({
  state: {
    imgUrl:'https://upup-1308592621.cos.ap-chongqing.myqcloud.com/zah1%',
    documentHeight:500,
    openId:null,
    userInfo:{},
    projectInfo:{},
    userinfo:{},
    clist: [
      "中国(不含港澳)--CHINA",
      "香港--HONG KONG",
      "非中国(外邮DHL)--NOTCHINA",
      "美国--- USA",
      "英国--- UK",
      "澳大利亚--AUSTRALIA",
      "奥地利--AUSTRIA",
      "阿尔及利亚--Algeria",
      "阿尔巴尼亚--Albania",
      "安哥拉--Angola",
      "巴林--BAHRAIN",
      "孟加拉国--BANGLADESH",
      "比利时--BELGIUM",
      "阿曼--Oman",
      "马尔代夫--Maldives",
      "斯洛伐克--Slovakia",
      "玻利维亚--BOLIVIA",
      "巴西--BRAZIL",
      "文莱--BRUNEI",
      "保加利亚--BULGARIA",
      "加拿大--CANADA",
      "开曼群岛--CAYMAN ISLANDS",
      "马其顿--Macedonia",
      "智利--CHILE",
      "哥伦比亚--COLOMBIA",
      "萨尔瓦多--EL SALVADOR",
      "科特迪瓦--COTE D IVOIRE",
      "克罗地亚--CROATIA",
      "卢旺达--Rwanda",
      "塞浦路斯--CYPRUS",
      "捷克共和国--CZECH REPUBLIC, THE",
      "丹麦--DENMARK",
      "多米尼加共和国--DOMINICAN REPUBLIC",
      "厄尔多瓜--ECUADOR",
      "埃及--EGYPT",
      "爱沙尼亚--ESTONIA",
      "芬兰--FINLAND",
      "法国--FRANCE",
      "德国--GERMANY",
      "希腊--GREECE",
      "关岛--GUAM",
      "危地马拉--GUATEMALA",
      "海地--HAITI",
      "匈牙利--HUNGARY",
      "冰岛--ICELAND",
      "牙买加--Jamaica",
      "印度--INDIA",
      "印度尼西亚--INDONESIA",
      "爱尔兰共和国--IRELAND, REPUBLIC OF",
      "以色列--ISRAEL",
      "意大利--ITALY",
      "日本--JAPAN",
      "约旦--JORDAN",
      "哈萨克斯坦--KAZAKHSTAN",
      "肯尼亚--KENYA",
      "韩国--SOUTH K",
      "科威特--KUWAIT",
      "拉脱维亚--LATVIA",
      "立陶宛--LITHUANIA",
      "卢森堡--LUXEMBOURG",
      "马来西亚--MALAYSIA",
      "马耳他--MALTA",
      "毛里求斯--MAURITIUS",
      "墨西哥--MEXICO",
      "摩尔多瓦--MOLDOVA, REPUBLIC OF",
      "黑山--MONTENEGRO, REPUBLIC OF",
      "摩洛哥--MOROCCO",
      "缅甸--MYANMAR",
      "荷兰--NETHERLANDS, THE",
      "新喀里多尼亚--NEW CALEDONIA",
      "新西兰--NEW ZEALAND",
      "莫桑比克--Mozambique",
      "尼日利亚--NIGERIA",
      "挪威--NORWAY",
      "巴基斯坦--PAKISTAN",
      "秘鲁--PERU",
      "菲律宾--PHILIPPINES, THE",
      "波兰--POLAND",
      "伊拉克--Iraq",
      "葡萄牙--PORTUGAL",
      "波多黎各--PUERTO RICO",
      "卡塔尔--QATAR",
      "罗马尼亚--ROMANIA",
      "沙特阿拉伯--SAUDI ARABIA",
      "塞内加尔--SENEGAL",
      "塞尔维亚--SERBIA, REPUBLIC OF",
      "新加坡--SINGAPORE",
      "斯洛文尼亚--SLOVENIA",
      "南非--SOUTH AFRICA",
      "西班牙--SPAIN",
      "斯里兰卡--SRI LANKA",
      "瑞典--SWEDEN",
      "萨摩亚--Samoa",
      "瑞士联邦--SWITZERLAND",
      "坦桑尼亚--TANZANIA",
      "土耳其--TURKEY",
      "泰国--THAILAND",
      "特克斯与凯科斯群岛--TURKS AND CAICOS ISLANDS",
      "阿拉伯联合酋长国--UNITED ARAB EMIRATES",
      "乌兹别克斯坦--UZBEKISTAN",
      "瓦努阿图--VANUATU",
      "委内瑞拉--VENEZUELA",
      "越南--VIETNAM",
      "赞比亚--ZAMBIA",
      "乌克兰--Ukraine",
      "乌拉圭--Uruguay",
      "刚果共和国--Congo",
      "尼加拉瓜--Nicaragua",
      "洪都拉斯--Honduras",
      "乌干达--Uganda",
      "阿根廷--Argentina",
      "佛得角--CAPE VERDE",
    ],
    clistS: [
      "中国(不含港澳)--CHINA",
      "香港--HONG KONG",
      "非中国(外邮DHL)--NOTCHINA",
      "美国--- USA",
      "英国--- UK",
      "澳大利亚--AUSTRALIA",
      "奥地利--AUSTRIA",
      "阿尔及利亚--Algeria",
      "阿尔巴尼亚--Albania",
      "安哥拉--Angola",
      "巴林--BAHRAIN",
      "孟加拉国--BANGLADESH",
      "比利时--BELGIUM",
      "阿曼--Oman",
      "马尔代夫--Maldives",
      "斯洛伐克--Slovakia",
      "玻利维亚--BOLIVIA",
      "巴西--BRAZIL",
      "文莱--BRUNEI",
      "保加利亚--BULGARIA",
      "加拿大--CANADA",
      "开曼群岛--CAYMAN ISLANDS",
      "马其顿--Macedonia",
      "智利--CHILE",
      "哥伦比亚--COLOMBIA",
      "萨尔瓦多--EL SALVADOR",
      "科特迪瓦--COTE D IVOIRE",
      "克罗地亚--CROATIA",
      "卢旺达--Rwanda",
      "塞浦路斯--CYPRUS",
      "捷克共和国--CZECH REPUBLIC, THE",
      "丹麦--DENMARK",
      "多米尼加共和国--DOMINICAN REPUBLIC",
      "厄尔多瓜--ECUADOR",
      "埃及--EGYPT",
      "爱沙尼亚--ESTONIA",
      "芬兰--FINLAND",
      "法国--FRANCE",
      "德国--GERMANY",
      "希腊--GREECE",
      "关岛--GUAM",
      "危地马拉--GUATEMALA",
      "海地--HAITI",
      "匈牙利--HUNGARY",
      "冰岛--ICELAND",
      "牙买加--Jamaica",
      "印度--INDIA",
      "印度尼西亚--INDONESIA",
      "爱尔兰共和国--IRELAND, REPUBLIC OF",
      "以色列--ISRAEL",
      "意大利--ITALY",
      "日本--JAPAN",
      "约旦--JORDAN",
      "哈萨克斯坦--KAZAKHSTAN",
      "肯尼亚--KENYA",
      "韩国--SOUTH K",
      "科威特--KUWAIT",
      "拉脱维亚--LATVIA",
      "立陶宛--LITHUANIA",
      "卢森堡--LUXEMBOURG",
      "马来西亚--MALAYSIA",
      "马耳他--MALTA",
      "毛里求斯--MAURITIUS",
      "墨西哥--MEXICO",
      "摩尔多瓦--MOLDOVA, REPUBLIC OF",
      "黑山--MONTENEGRO, REPUBLIC OF",
      "摩洛哥--MOROCCO",
      "缅甸--MYANMAR",
      "荷兰--NETHERLANDS, THE",
      "新喀里多尼亚--NEW CALEDONIA",
      "新西兰--NEW ZEALAND",
      "莫桑比克--Mozambique",
      "尼日利亚--NIGERIA",
      "挪威--NORWAY",
      "巴基斯坦--PAKISTAN",
      "秘鲁--PERU",
      "菲律宾--PHILIPPINES, THE",
      "波兰--POLAND",
      "伊拉克--Iraq",
      "葡萄牙--PORTUGAL",
      "波多黎各--PUERTO RICO",
      "卡塔尔--QATAR",
      "罗马尼亚--ROMANIA",
      "沙特阿拉伯--SAUDI ARABIA",
      "塞内加尔--SENEGAL",
      "塞尔维亚--SERBIA, REPUBLIC OF",
      "新加坡--SINGAPORE",
      "斯洛文尼亚--SLOVENIA",
      "南非--SOUTH AFRICA",
      "西班牙--SPAIN",
      "斯里兰卡--SRI LANKA",
      "瑞典--SWEDEN",
      "萨摩亚--Samoa",
      "瑞士联邦--SWITZERLAND",
      "坦桑尼亚--TANZANIA",
      "土耳其--TURKEY",
      "泰国--THAILAND",
      "特克斯与凯科斯群岛--TURKS AND CAICOS ISLANDS",
      "阿拉伯联合酋长国--UNITED ARAB EMIRATES",
      "乌兹别克斯坦--UZBEKISTAN",
      "瓦努阿图--VANUATU",
      "委内瑞拉--VENEZUELA",
      "越南--VIETNAM",
      "赞比亚--ZAMBIA",
      "乌克兰--Ukraine",
      "乌拉圭--Uruguay",
      "刚果共和国--Congo",
      "尼加拉瓜--Nicaragua",
      "洪都拉斯--Honduras",
      "乌干达--Uganda",
      "阿根廷--Argentina",
      "佛得角--CAPE VERDE",
    ]

  },
  getters: {
  },
  mutations: {
    setDocumentHeight(state,data){
      state.documentHeight=data
    },
    setOpenid(state,data){
      state.openId=data
    },
    setUserInfo(state,data){
      state.userinfo=data
    },
    setProjectInfo(state,data){
      state.projectInfo=data
    },
  },
  actions: {
    updateUserInfoStatus(context){
      getUserInfoApi().then(res => {
        if (res.data.code == 1) {
          localStorage.setItem('userInfo', JSON.stringify(res.data.data.userinfo))
          context.commit('setUserInfo',res.data.data.userinfo)
        } else {
          localStorage.removeItem('userInfo')
          context.commit('setUserInfo',{})
          ElMessage({
            message: res.data.msg,
            type: 'warning',
          })
        }
      })
    }
  },
  modules: {
  }
})
