<script setup>
import {ElCard, ElIcon} from "element-plus";
import {WarningFilled} from "@element-plus/icons-vue";
import {activityApi} from "@/service/index.js";
import {useStore} from "vuex";
import {ref} from "vue";

const store = useStore()
let info = ref({})
activityApi().then(res => {
  if (res.data.code == 1) {
    console.log(res)
    info.value = res.data.data
  }
})
</script>

<template>
  <el-card style="width: 100%">
    <template #header>
      <div class="h-box">
        <img src="@/assets/xiaoxi.png" class="xxic" alt="">
        <span>&nbsp; 2024.最实惠便捷的国际物流单号网</span>
      </div>
    </template>
    <template #default>
      <p style="font-size: 14pt;"> 
        寻找国际物流单号？不必再费时费力！欢迎来到我们的网站，这里提供着无与伦比的价格和卓越的技术，让你的物流追踪体验变得轻松而愉快。
      </p>
      <p style="font-size: 14pt;"> 
        <strong>最快速的国际物流单号更新速度：&nbsp;</strong>每日数十万的国际物流单号数据更新，确保你始终掌握最新的物流信息。
      </p>
      <p style="font-size: 14pt;"> 
        <strong>最实惠的国际快递单号兑换价格：&nbsp;</strong>我们提供全网最低廉的价格，不再区分搜索方式。一步到位，诚意满满，为你节省成本的同时提供卓越的服务。
      </p>
      <p style="font-size: 14pt;"> 
        <strong>前往国际物流单号查询：&nbsp;</strong>
        <a href="#contact-form" style="color: rgb(53, 152, 219); text-decoration: underline;">Go! Go! Go!</a>
      </p>
      <p style="font-size: 14pt;"> 
        本站目前提供：DHL单号，后续有机会将陆续提供：USPS单号，EMS单号，EUB单号等。平台单号可用于：速卖通 敦煌网 eBayAmazon等跨境电商平台。
        <br>单号均为官方真实单号，当天上网，可通过官方物流查询通道查询轨迹信息。
      </p>
      <p style="font-size: 14pt;"> 查询地址: &nbsp;
        <a title="DHL官方批量查询平台"
        href="https://mydhlplus.dhl.com/cn/zh/tracking.html#/track-by-number"
        target="_blank" rel="noopener"
        style="color: rgb(53, 152, 219); text-decoration: underline;">DHL官方查询平台</a>
        &nbsp;
<!--        <a title="DHL官方批量查询平台" href="https://www.usps.com/" target="_blank" rel="noopener"-->
<!--           style="color: rgb(53, 152, 219); text-decoration: underline;">USPS官方查询平台</a>-->
        <a title="DHL官方批量查询平台" href="https://www.17track.net/zh-cn" target="_blank"
        rel="noopener" style="color: rgb(53, 152, 219); text-decoration: underline;">17TRACK全球物流查询平台</a></p>

      <div class="prompt">
        <p class="tit">
          <el-icon color="red" size="22px">
            <WarningFilled/>
          </el-icon>
          <span style="font-size: 14pt;">
          温馨提示
          </span>
        </p>
        <!-- <div class="p-detail" style="font-size: 13pt;" v-html="info.tips_str" >
        </div> -->
        <div class="p-detail" style="font-size: 13pt;" >
          <p> 1. 本站兑换任意单号，通通仅需1个兑换币。请先充值兑换币后进行兑换。</p>
          <p> 2. 本站单号只兑换1次，单号兑换后，系统会自动下架该单号，不会重复兑换，请放心兑换并使用。</p>
          <p> 3. 本网站单号只限用于物流时效参考，请勿用作诈骗等违法行为，一经发现单号用作诈骗等违法行为，本站有权关闭其账号。</p>
          <p> 4. 遇到非人为可控原因造成的不可用单号，不予补偿，没有售后，没有任何上网信息或已被使用的单号可联系客服更换。</p>
          <p> 5. 使用不了的单号，或2天后仍没有更新轨迹的单号，把单号发给客服可以免费更换。</p>
          <p> <span style="background-color: red;"> 6. 尽量选择有预计送达日期的单号，没有预计送达日期的有低概率出现不揽收的异常情况，</span><span style="background-color: orange;">兑换后请点击复制单号后去官网验证是否正常揽收，异常情况可以联系客服更换。</span></p>
        </div>

      </div>

      <div class="prompt sales-promotion">
        <p class="tit">
          <el-icon color="yellow" size="22px">
            <WarningFilled/>
          </el-icon>
          <span style="font-size: 14pt;">
          促销活动
          </span>
        </p>
        <!-- <div class="p-detail" style="font-size: 13pt;" v-html="info.activity_str">
        </div> -->
        <div class="p-detail" style="font-size: 13pt;">
          <p> 1. 邀请码福利： </p>
          <p>    - <span style="background-color: red;">单笔充值128及以上可永久开启邀请码</span>，邀请好友可获得奖励。 </p>
          <p>    - 好友通过邀请码注册，首次充值，双方都将额外获得本次充值<span style="background-color: red;">10%</span>的兑换币。 </p>
          <p> 2. QQ客服福利： </p>
          <p>    - 添加客服绑定账号，可免费领取：兑换币*20。 </p>
          <p> 3. 充值促销： </p>
          <p>    - 【充值6/30元】单价0.25元 </p>
          <p>    - 【充值68元】<span style="background-color: red;">加赠10%</span> </p>
          <p>    - 【充值128元】<span style="background-color: red;">加赠15%</span> </p>
          <p>    - 【充值328元】<span style="background-color: red;">加赠20%</span> </p>
          <p>    - 【充值648元】<span style="background-color: red;">加赠25%</span> </p>
        </div>

      </div>

    </template>
  </el-card>
</template>

<style  lang="less" scoped>
.h-box {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  .xxic {
    width: 30px;
    margin-right: 5px;
  }
}

.detail {
  display: flex;
  margin: 0;
  padding: 0;
}

.prompt {
  background: rgb(255, 229, 0);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 20px;

  .tit {
    display: flex;
    align-content: center;
    color: red;
    font-weight: bold;
    margin: 0;
    margin: 5px 0;

    span {
      margin-left: 4px;
      display: flex;
      align-items: center;
    }
  }

  .p-detail {
    line-height: 25px;
  }
}

.sales-promotion {
  background: rgb(45, 194, 107);
  color: white;

  .tit {
    color: yellow;
  }
}
</style>