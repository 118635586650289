// @ts-ignore
import axios from 'axios';


export function getCaptchaApi(captcha_id) {
  return axios({
    url: 'https://aaap.trackcbec.com/index?s=captcha/' + captcha_id,
    method: 'GET'
  })
};

export function registerApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/user/signUp',
    method: 'POST',
    data
  })
};
export function registerApi2(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/user/register',
    method: 'POST',
    data
  })
};

export function loginApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/user/login',
    method: 'POST',
    data
  })
};

export function sendMsgApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/user/sendMsg',
    method: 'POST',
    data
  })
};

export function getUserInfoApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/user/info',
    method: 'POST',
    data
  })
};

// 积分变动记录（还是充值+邀请赠送的记录）：
export function scoreLogApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/user/scoreLog',
    method: 'POST',
    data
  })
};
// 单号列表：
// page_size     每页显示数量  默认50
// begin_time 开始时间
// end_time 结束时间
// types  类型传DHL、USPS、做下拉选择
// start_country  发货地
// end_country  收货地
export function searchListApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/track/searchList',
    method: 'POST',
    data
  })
};

// 我的单号：
//     page_size     每页显示数量  默认50
// page  第几页
export function myListApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/track/myList',
    method: 'POST',
    data
  })
};

//单号兑换（单个）：
export function buyApi(data) {
  console.log('data',data)
  return axios({
    url: 'https://aaap.trackcbec.com/track/buy',
    method: 'POST',
    data
  })
};

// 单号兑换（批量）：
export function buyBatchApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/track/buyBatch',
    method: 'POST',
    data
  })
};
// 充值：
// https://aaap.trackcbec.com/shop/createOrder
//     pid  product接口里面返回的ID
// price 产品的金额
// type  1.微信、2.支付宝（现在只弄微信，固定传1）

export function createOrderApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/shop/createOrder',
    method: 'POST',
    data
  })
};

// 获取充值列表：
// https://aaap.trackcbec.com/shop/product
export function productApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/shop/product',
    method: 'POST',
    data
  })
};
// 充值状态查询：
// https://pppay.trackcbec.com/checkOrder
//     orderId
export function checkOrderApi(data) {
  return axios({
    url: 'https://pppay.trackcbec.com/checkOrder',
    method: 'POST',
    data
  })
};
export function activityApi(data) {
  return axios({
    url: 'https://aaap.trackcbec.com/user/activity',
    method: 'POST',
    data
  })
};



axios.interceptors.request.use(res=>{
  if(localStorage.getItem('userInfo')){
    let info=JSON.parse(localStorage.getItem('userInfo'))
    res.headers={...res.headers,'token':info.token}
  }
  return res
})
