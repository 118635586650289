<script setup>
import {ref, reactive, onMounted} from "vue";
import {ElDialog, ElIcon, ElFormItem, ElForm, ElButton, ElInput, ElMessage} from 'element-plus'
import {Lock, User, UserFilled} from "@element-plus/icons-vue";
import {getCaptchaApi, loginApi, registerApi, sendMsgApi} from "@/service/index.js";
import {useStore} from "vuex";
import Recharge from "@/components/recharge.vue";
// 可以在组件中的任意位置访问 `store` 变量 ✨
const store = useStore()
const rules = reactive({
  account: [
    {required: true, message: '请输入账号', trigger: 'blur'},
    {min: 2, max: 25, message: '请输入正确的账号', trigger: 'blur'},
  ],
  password: [
    {required: true, message: '请输入密码', trigger: 'blur'},
    {min: 2, max: 25, message: '请输入正确的密码', trigger: 'blur'},
  ]
})
const rules2 = reactive({
  username: [
    {required: true, message: '请输入用户名', trigger: 'blur'},
    {min: 2, max: 25, message: '请输入正确的用户名', trigger: 'blur'},
  ],
  password: [
    {required: true, message: '请输入密码', trigger: 'blur'},
    {min: 6, max: 25, message: '请输入正确的密码', trigger: 'blur'},
  ],
  email: [
    {required: true, message: '请输入邮箱', trigger: 'blur'},
    {min: 5, max: 25, message: '请输入正确的邮箱', trigger: 'blur'},
  ],
  mobile: [
    {required: true, message: '请输入手机号', trigger: 'blur'},
    {min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur'},
  ],
  invite_code: [
    {required: false, message: '请输入邀请码', trigger: 'blur'},
    // {min: 2, max: 25, message: '请输入正确的账号', trigger: 'blur'},
  ],
  captcha: [
    {required: true, message: '请输入验证码', trigger: 'blur'},
    {min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur'},
  ],
})
let dialogVisible = ref(false)
let dialogRejister = ref(false)
let dialogMoney = ref(false)
let dialogCheck = ref(false)
let captchaImg = ref('')
let lForm = ref(null)
let rForm = ref(null)
let hh = ref(null)
let form = ref({
  account: '',
  password: '',
})


console.log('store.userinfo10', store.state.userinfo)
let rejisterForm = ref({
  email: '',
  password: '',
  username: '',
  mobile: '',
  invite_code: '',
  captcha: '',// 验证码
  code: '',
  captcha_id: '',	// 调用验证码生成的ID
})
const rejiste = async () => {
  await rForm.value.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      registerApi(rejisterForm.value).then(res => {
        if (res.data.code == 1) {
          ElMessage({
            message: '注册成功',
            type: 'success',
          })
          dialogRejister.value = false
          dialogVisible.value = false
          store.commit('setUserInfo', res.data.data.userinfo)
          localStorage.setItem('userInfo', JSON.stringify(res.data.data.userinfo))
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'warning',
          })
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
  return

}
const login = async () => {
  await lForm.value.validate((valid, fields) => {
    if (valid) {
      loginApi(form.value).then(res => {
        if (res.data.code == 1) {
          ElMessage({
            message: '登录成功',
            type: 'success',
          })
          dialogRejister.value = false
          dialogVisible.value = false
          store.commit('setUserInfo', res.data.data.userinfo)
          localStorage.setItem('userInfo', JSON.stringify(res.data.data.userinfo))
          // store.commit('updateUserinfo',res.data.data.userinfo)
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'warning',
          })
        }
      })
      console.log('submit!')
    } else {
      console.log('error submit!', fields)
    }
  })
  return

}
const toRejist = () => {
  dialogVisible.value = false
  dialogRejister.value = true
  rejisterForm.value.captcha_id = new Date().valueOf().toString().slice(4, -1)
}
const updateCaptcha = () => {
  rejisterForm.value.captcha_id = new Date().valueOf().toString().slice(4, -1)
}
const clear = () => {
  localStorage.removeItem('userInfo')
  store.userinfo = {}
  store.commit('setUserInfo', {})

}
store.dispatch('updateUserInfoStatus')
onMounted(() => {
  if (localStorage.getItem('userInfo')) {
    store.commit('setUserInfo', JSON.parse(localStorage.getItem('userInfo')))
    // localStorage.setItem('userInfo',JSON.stringify(res.data.data.userinfo))
  }
})
let codeTimer = ref(null)
const codeResetTime = ref(0)
const setTime = () => {
  if (codeTimer.value) return;
  codeResetTime.value = 60
  codeTimer.value = setInterval(() => {
    codeResetTime.value -= 1
    if (codeResetTime.value <= 0) {
      clearInterval(codeTimer.value)
      codeResetTime.value = 0
      codeTimer.value = null
    }
  }, 1000)
}
const sendMsg = () => {
  if (!rejisterForm.value.mobile || rejisterForm.value.mobile.length != 11) {
    ElMessage({
      message: "请输入正确的手机号",
      type: 'warning',
    })
    return
  }
  dialogCheck.value = true
  updateCaptcha()
}
const sendMsg2 = () => {
  sendMsgApi({
    mobile: rejisterForm.value.mobile,
    captcha: rejisterForm.value.captcha,
    captcha_id: rejisterForm.value.captcha_id,
  }).then(res => {
    if (res.data.code == 1) {
      setTime()
      ElMessage({
        message: '发送成功',
        type: 'success',
      })
      dialogCheck.value = false
    } else {
      ElMessage({
        message: res.data.msg,
        type: 'warning',
      })
    }
  })
}
const toLoing = () => {
  dialogVisible.value = true
  dialogRejister.value = false
  rejisterForm.value={
    email: '',
    password: '',
    username: '',
    mobile: '',
    invite_code: '',
    captcha: '',// 验证码
    code: '',
    captcha_id: '',	// 调用验证码生成的ID
  }
}
const ca=()=>{
  console.log('hh')
  dialogVisible.value=false
}
updateCaptcha()
</script>

<template>
  <div class="user-box" ref="hh">
    <div id="contact-form" class="u-box">
      <span @click="dialogVisible=true" style="cursor: pointer;color: white" v-if="!store.state.userinfo.username" >亲，您尚未登录，<span data-v-2fb5d6b3="" style="color: yellow; cursor: pointer; text-decoration: underline;">点击登录/注册账户，即可兑换 &gt;</span> </span>
      <template  v-else >
      <img src="@/assets/coin.png" style="width: 45px;margin-right: 5px" alt="">
      <span>欢迎您，{{store.state.userinfo.username}} </span>
      <span style="margin-left: 10px; color: yellow;">您的邀请码：</span>
      <span v-if="store.state.userinfo.invite_status" style="color: yellow;text-decoration: underline;">{{store.state.userinfo.invite_code}}</span>
      <span v-else style="color: yellow;text-decoration: underline;">暂未开启</span>
      <span style="margin-left: 10px" >兑换币余额：{{store.state.userinfo.score}}</span>
      <span  style="cursor: pointer;color: red;margin-left: 20px;text-decoration: underline;" @click="dialogMoney=true">兑换币充值</span>
      <span style="color: yellow;cursor: pointer;text-decoration: underline;margin-left: 20px;" @click="clear">退出账户</span>
</template>
    </div>

  </div>
  <el-dialog
      v-model="dialogVisible"
      title="登录"
      width="500"
  >
    <template #default>
      <el-form :rules="rules" class="login-form" :model="form" ref="lForm" label-width="auto" style="max-width: 600px">
        <el-form-item label="" size="large" prop="account">
          <el-input  style="height: 50px" size="large" v-model="form.account" placeholder="请输入用户名或邮箱或手机号登录" >
          <template #prepend>  <el-icon size="15" ><User/></el-icon></template>
          </el-input>
        </el-form-item>
        <el-form-item label="" size="large" prop="password">
          <el-input  style="height: 50px" size="large" v-model="form.password" type="password" placeholder="请输入密码">
            <template #prepend> <el-icon size="15" ><Lock/></el-icon></template>
          </el-input>

        </el-form-item>
      </el-form>
      <p class="to-rejister" @click="toRejist">没有账号？去注册</p>
    </template>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="ca">取消</el-button>
        <el-button type="primary" @click="login">
          登录
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
      v-model="dialogRejister"
      title="注册"
      width="500"
  >
    <template #default>
      <el-form :rules="rules2" class="login-form" ref="rForm" :model="rejisterForm" label-width="auto"
               style="max-width: 600px">
        <el-form-item label="用户名" size="large" prop="username">
          <el-input v-model="rejisterForm.username" placeholder="请输入用户名(找回密码需核实)"/>
        </el-form-item>
        <el-form-item label="密码" size="large" prop="password">
          <el-input size="large" v-model="rejisterForm.password" type="password" placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item label="手机号" size="large" prop="mobile">
          <el-input size="large" v-model="rejisterForm.mobile" placeholder="请输入手机号(找回密码需核实)"/>
        </el-form-item>
        <el-form-item label="邮箱" size="large" prop="email">
          <el-input v-model="rejisterForm.email" placeholder="请输入邮箱(找回密码需核实)"/>
        </el-form-item>
        <el-form-item label="邀请码" size="large" prop="invite_code">
          <el-input v-model="rejisterForm.invite_code" placeholder="请输入邀请码"/>
        </el-form-item>
        <el-form-item label="验证码" size="large" prop="captcha">
          <div style="display: flex;align-items: flex-start">
                        <el-input size="large" style="width: 200px;" v-model="rejisterForm.captcha" placeholder="请输入验证码"/>
                        <img :src="'https://aaap.trackcbec.com/index?s=captcha/'+rejisterForm.captcha_id" @click="updateCaptcha"
                             v-if="rejisterForm.captcha_id" class="captcha-pic" alt="">
<!--            <el-input size="large" style="width: 200px;" v-model="rejisterForm.code" placeholder="请输入验证码"/>-->
<!--            <el-button style="margin-left: 10px" :disabled="codeResetTime!=0"-->
<!--                       :type="codeResetTime==0?'primary':'default'" @click="sendMsg">-->
<!--              {{ codeResetTime == 0 ? '发送验证码' : `${codeResetTime}s后可重新发送` }}-->
<!--            </el-button>-->
          </div>
        </el-form-item>
      </el-form>
      <p class="to-rejister" @click="toLoing">已有账号？去登录</p>
    </template>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogRejister = false">取消</el-button>
        <el-button type="primary" @click="rejiste">
          注册
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
      v-model="dialogMoney"

      :show-close="false"
      width="688"
  >
    <template #default>
      <Recharge v-if="dialogMoney" @close="dialogMoney=false"></Recharge>
    </template>
  </el-dialog>
  <el-dialog
      v-model="dialogCheck"
      top="32vh"
      title="验证"
      width="500"
  >
    <template #default>
      <div style="display: flex;align-items: flex-start">
        <el-input size="large" style="width: 200px;" v-model="rejisterForm.captcha" placeholder="请输入验证码"/>
        <img :src="'https://aaap.trackcbec.com/index?s=captcha/'+rejisterForm.captcha_id" @click="updateCaptcha"
             v-if="rejisterForm.captcha_id" class="captcha-pic" alt="">
      </div>
    </template>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogCheck = false">取消</el-button>
        <el-button type="primary" @click="sendMsg2">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="less" scoped>
.header-box {
  display: flex;
  width: 100%;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 19.2px;
  font-weight: bold;

  img {
    width: 40px;
    margin-right: 10px;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    color: blue;
    font-size: 15px;
    font-weight: normal;
    cursor: pointer;
    text-underline: blue;

    span {
      margin-left: 5px;
    }
  }
}

.login-form {

}

.to-rejister {
  cursor: pointer;
  color: rgb(53, 152, 219);
}

.captcha-pic {
  width: 120px;
  margin-left: 20px;
}

.user-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  margin-top: 30px;
  img{
    transform: translateY(-10px);
  }
  .u-box{
    height: 40px;
    width: 770px;
    display: flex;
    background: #66b1ff;
    border-radius: 10px;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: bold;
  }
}

</style>