import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Demo from '@/views/index/index.vue'
/*
* checkAuth:检查授权
* */
const routes = [
  {
    path: '/',
    name: 'index',
    meta:{checkAuth:true},
    component: Demo
  },
  {
    path: '/index',
    name: 'index2',
    meta:{checkAuth:true},
    component: Demo
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
