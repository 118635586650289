<script setup>
import {useStore} from "vuex";
import {StarFilled} from "@element-plus/icons-vue"
const store = useStore()
const collection=()=>{
  try {
    window.external.addFavorite(sURL, sTitle);//支持ie
  } catch (error) {
    try {
      window.sidebar.addPanel(sTitle, sURL, "");//支持火狐
    } catch (error) {
      alert("您的浏览器不支持,请按 Ctrl+D 手动收藏!。");//不支持需要手动添加
    }
  }
}
</script>

<template>
  <header class="header-box" ref="hh" >
    <div class="left">
      <img src="@/assets/ic.png" class="ic" alt="">
      <span>国际快递单号网</span>
    </div>
    <div class="right">

      <el-button :icon="StarFilled" round color="#f56c6c" style="color:white;" size="large" @click="collection" >谨防丢失，收藏本站</el-button>
    </div>
  </header>

</template>

<style  lang="less" scoped>
.header-box {
  display: flex;
  width: 100%;
  height: 8vh;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 19.2px;
  font-weight: bold;

  img {
    width: 40px;
    margin-right: 10px;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    color: blue;
    font-size: 15px;
    font-weight: normal;
    cursor: pointer;
    text-underline: blue;
    span{
      margin-left: 5px;
    }
  }
}
</style>