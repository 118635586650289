<script setup>
import Sheader from "@/components/header.vue"
import {h, ref} from 'vue';
import {useStore} from "vuex";
import Announcement from "@/components/announcement.vue";
import Cuser from "@/components/cuser.vue";
import OrderList from "@/components/orderList.vue";
import {ElTabs,ElTabPane} from "element-plus"
import MyOrder from "@/components/myOrder.vue";
import MoneyList from "@/components/moneyList.vue";

const store = useStore()
const activeIndex = ref('1')
let hh = ref(false)
const scro = (e, e2) => {
  if (e.srcElement.scrollTop > 0) {
    hh.value = true
  } else {
    hh.value = false
  }
}
const tbc = (v) => {
  console.log(v)
}
</script>

<template>
  <div class="t-box" >
    <Sheader></Sheader>
    <Announcement></Announcement>
    <Cuser></Cuser>
    <el-card>
      <el-tabs type="border-card" v-model="activeIndex">
        <el-tab-pane key="1" name="1" label="单号列表">
          <OrderList v-if="activeIndex==1"></OrderList>
        </el-tab-pane>
        <el-tab-pane key="2" name="2" label="我的单号">
                    <MyOrder v-if="activeIndex==2" ></MyOrder>
        </el-tab-pane>
        <el-tab-pane key="3" name="3" label="兑换币记录">
                    <MoneyList v-if="activeIndex==3" ></MoneyList>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <div class="q-box">
      <img src="@/assets/q2.png" alt="">
      <span>QQ客服</span>
      <img src="@/assets/qq.png" class="qq" alt="">
    </div>
  </div>
</template>

<style  lang="less" scoped>
.t-box {
  width: 100vw;
  height: 100vh;
  padding: 0 10vw;
  overflow-y: scroll;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0px;
  }
}
.q-box{
  cursor: pointer;
  width: 77px;
  height: 77px;
  padding-top: 10px;
  padding-bottom: 6px;
  background: #7f5fef;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
  text-align: center;
  color: #fff;
  font-size: 12px;
  position: fixed;
  right: 10px;
  bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2001;

  &:hover{
    .qq{
      display: block;
    }
  }
  img{
    width: 42px;
  }
  .qq{
    width: 140px;
    position: absolute;
    transform: translate(-122px,-36px);
    z-index: 105;
    display: none;
    border-radius: 5px;
  }
}
/deep/.el-dialog{
  border-radius: 8px !important;
}
</style>