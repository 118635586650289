<script setup>
import {onMounted, ref} from 'vue'
import {
  ElCard,
  ElTableColumn,
  ElSelectOption,
  ElTable,
  ElRow,
  ElCol,
  ElDatePicker,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElOption,
  ElSelect,
  ElButton
} from "element-plus";
import {buyApi, searchListApi} from "@/service/index.js";
import dayjs from "dayjs";
import {useStore} from "vuex";
import {Delete, Search, TurnOff} from "@element-plus/icons-vue";
import mitts from "@/utils/mitt";
import {onUnmounted} from 'vue'
const store = useStore()

let searParams = ref({
  page_size: 50, //     每页显示数量  默认50
  begin_time: '', // 开始时间
  end_time: '', // 结束时间
  types: '', //  类型传DHL、USPS、做下拉选择
  package_num_type: 0, //  包裹数量类型
  delivery_status: 0, //  是否有预计送达日期
  start_country: '', //  发货地
  start_address: '', //  发货地
  end_country: '', // 收货地
  end_address: '', // 收货地
})
let pagination = ref({
  current: 1,
  pageSize: 50,
  total: 100,
  showSizeChanger: false,
})
let payInfo = ref({})
let tabBox = ref(null)
let oBox = ref(null)
let yHeight = ref(0)
let loading = ref(false)
let selectedRowKeys = ref([])
let dialogBuy = ref(false)
let olength=ref(0)
let multipleTableRef=ref(null)
const handleSelectionChange = (e,e2) => {
  console.log(e,e2)
  console.log('multipleTableRef',multipleTableRef.value.getSelectionRows())
  olength.value=multipleTableRef.value.getSelectionRows().length
  
}
const clearSelection=()=>{
  multipleTableRef.value.clearSelection()
}

const cdata = ref([]);
const getOrderList = (reset=false) => {
  if(reset===true){
    console.log('重置！')
    pagination.value.current=1
  }
  pagination.value.pageSize=searParams.value.page_size-0
  loading.value = true
  let t1 = searParams.value.begin_time ? new Date(searParams.value.begin_time).valueOf() : null
  let t2 = searParams.value.end_time ? new Date(searParams.value.end_time).valueOf() : null
  searchListApi({...searParams.value, page: pagination.value.current, begin_time: t1, end_time: t2}).then(res => {
    if (res.data.code == 1) {
      cdata.value = res.data.data.data
      pagination.value.total = res.data.data.total
    } else {
      ElMessage({
        message: res.data.msg,
        type: 'warning',
      })
    }
    console.log('data', cdata.value)
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
})
const pageChange = (p) => {
  pagination.value.current = p
  getOrderList()
}
const ok1 = (v) => {
  // searParams.value.begin_time = v.$d
}
const ok2 = (v) => {
  // searParams.value.end_time= v.$d
}
const reset = () => {
  searParams.value = {
    page_size: 50, //     每页显示数量  默认50
    begin_time: '', // 开始时间
    end_time: '', // 结束时间
    types: '', //  类型传DHL、USPS、做下拉选择
    package_num_type: 0, //  包裹数量类型
    delivery_status: 0, //  是否有预计送达日期
    start_country: '', //  发货地
    start_address: '', //  发货地
    end_country: '', // 收货地
    end_address: '', // 收货地
  }
  pagination.value = {
    current: 1,
    pageSize: 50,
    total: 100
  }
  getOrderList()

}
const buy = (scope) => {
  console.log('scope',scope)
  loading.value = true
  if(scope){
    buyApi({ids: scope.row.id}).then(res => {
      if (res.data.code == 1) {
        selectedRowKeys.value = []
        payInfo.value = res.data.data
        dialogBuy.value = true
        payInfo.value.waybill_sn=payInfo.value.waybill_sn.join('<br>')
        console.log('payInfo.value.waybill_sn',payInfo.value.waybill_sn)
        getOrderList()
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'warning',
        })
      }
    }).finally(() => {
      store.dispatch('updateUserInfoStatus')
      loading.value = false
    })
    return
  }
  console.log('selectedRowKeys', multipleTableRef.value.getSelectionRows())
  let ids=multipleTableRef.value.getSelectionRows().map(item=>item.id).join(',')
  console.log('ids',ids)
  if(ids.length<=1){
    ElMessage({
      message: '请勾选单号',
      type: 'error',
    })
    loading.value = false
    return;
  }
  buyApi({ids: ids}).then(res => {
    if (res.data.code == 1) {
      selectedRowKeys.value = []
      payInfo.value = res.data.data
      payInfo.value.waybill_sn=payInfo.value.waybill_sn.join('<br>')
      console.log('payInfo.value.waybill_sn',payInfo.value.waybill_sn)
      dialogBuy.value = true
      getOrderList()
    } else {
      ElMessage({
        message: res.data.msg,
        type: 'warning',
      })
    }
  }).finally(() => {
    store.dispatch('updateUserInfoStatus')
    loading.value = false
  })
}
const copyToClipboard = () => {
  let text = payInfo.value.waybill_sn.replaceAll('<br>','\n')
  var textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.opacity = 0;
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  ElMessage({
    message: "复制成功",
    type: 'success',
  })
  dialogBuy.value = false
}
getOrderList()
mitts.on('handleChange',getOrderList)
onUnmounted(()=>{
  mitts.off('handleChange',getOrderList)
})
</script>

<template>

  <div class="o-list-box" ref="oBox">
    <el-row :gutter="20">
      <el-form :model="searParams" class="search-box">
        <el-col :span="5">
          <div>
            <el-form-item label="发货地国家" prop="start_country">
              <el-select
                  show-search
                  placeholder="请选择发货地国家"
                  allow-clear
                  filterable
                  style="height: 40px"
                  v-model="searParams.start_country"
              >
                <el-option v-for="(item,index) in  store.state.clist" :label="item" :value="item.split('--').pop()">{{
                    item
                  }}
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发货地址" class="search-item-box" prop="start_address">
            <el-input style="height: 40px" v-model="searParams.start_address" placeholder="请输入发货地址" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上网时间" prop="begin_time" label-width="96px" >
            <el-date-picker style="height: 40px" type="datetime" show-time placeholder="开始时间" v-model="searParams.begin_time" @ok="ok1"/>
            <span style="margin: 0 5px">至</span>
            <el-date-picker style="height: 40px" type="datetime" show-time placeholder="结束时间" v-model="searParams.end_time" @ok="ok2"/>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="收货地国家" prop="end_country">
            <el-select
                style="height: 40px"
                show-search
                placeholder="请选择收货地国家"
                allow-clear
                filterable
                v-model="searParams.end_country"
            >
              <el-option v-for="(item,index) in  store.state.clistS" :label="item" :value="item.split('--').pop()">{{
                  item
                }}
              </el-option>
            </el-select>

          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="收货地址" class="search-item-box" prop="start_address">
            <el-input style="height: 40px" v-model="searParams.end_address" placeholder="请输入收货地址" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="每页显示数量" class="search-item-box" prop="end_country">
            <el-input style="height: 40px" v-model.number="searParams.page_size" type="=number"></el-input>
          </el-form-item>
        </el-col>
        
        <el-col :span="6">
        </el-col>

        <el-col :span="5">
          <div>
            <el-form-item prop="delivery_status" label="确认已揽收">
              <el-select
                  style="height: 40px"
                  ref="select"
                  show-search
                  allow-clear
                  placeholder="是否确认已揽收"
                  v-model="searParams.delivery_status"
              >
                <el-option
                    v-for="(item,index) in  ['无限制', '100%已揽收']"
                    :key="index"
                    :label="item"
                    :value="index"
                />
              </el-select>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="6">
          <div style="padding-right: 20px" >
            <el-form-item prop="package_num" label="包裹数量">
              <el-select
                  style="height: 40px"
                  ref="select"
                  show-search
                  allow-clear
                  placeholder="请选择包裹数量"
                  v-model="searParams.package_num_type"
              >
                <el-option
                    v-for="(item,index) in  ['无限制', '=1', '2~5', '6~10', '>10']"
                    :key="index"
                    :label="item"
                    :value="index"
                />
              </el-select>
            </el-form-item>
          </div>
        </el-col>




        <div class="b" style="width: 100%;">

          <div class="ba" style="width: 100%;">
            <span style="color: orange">（左侧这里可以勾选多个后，右侧点击批量兑换）</span>
          </div>

          <el-button :icon="Delete" @click="clearSelection" type="warning" size="large" round>一键清空</el-button>
          <el-button :icon="TurnOff" @click="buy(null)" type="success" round size="large">批量兑换{{olength>0?`(${olength}个)`:''}}</el-button>
          <!--        <a-button style="margin:0 5px" @click="reset" icon="download">一键清空</a-button>-->
          <el-button :icon="TurnOff" @click="reset" type="info" round size="large">重置</el-button>
          <el-button :icon="Search" @click="getOrderList" :loading="loading" type="primary" round size="large">搜索
          </el-button>
        </div>
      </el-form>
    </el-row>
    <el-affix :offset="0" direction="horizontal">
    <div class="tab-box" ref="tabBox">
      <el-table
          border
          ref="multipleTableRef"
          height="85.5vh"
          v-loading="loading"
          :data="cdata"
          style="width: 100%"
          current-row-key="id"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"/>
        <el-table-column label="快递单号" prop="waybill_sn" width="130"></el-table-column>
        <el-table-column property="start_address" label="发货地" />
        <el-table-column property="end_address" label="收货地" />

        <el-table-column  label="单号类型" width="90" align="center">
          <template #default="scope">
            <span>{{scope.row.types}}</span>
          </template>
        </el-table-column>
        <el-table-column property="types" label="单号状态" width="90" align="center">
          <el-button type="warning" size="small" >已上网</el-button>
        </el-table-column>
        <el-table-column  label="所需兑换币" width="90" align="center">
          <span>1</span>
        </el-table-column>
<!--        <el-table-column property="st" label="适用平台" width="170" tooltip-effect >-->
<!--          <el-button type="primary" size="small" >敦煌网,速卖通,亚马逊,eBay,独立站等跨境平台</el-button>-->
<!--        </el-table-column>-->
        <el-table-column property="online_str" label="上网时间" width="180"/>
        <el-table-column property="delivery_date" label="预计送达日期" width="110"/>
        <el-table-column property="package_num" label="包裹数量" width="80"/>
        <el-table-column property="st" label="操作" width="100" fixed="right" >
          <template #default="scope">
            <el-button size="large" type="primary" @click="buy(scope)" :loading="loading">兑换</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="p-box" style="width: 100%;height:7vh;display: flex;justify-content: center;align-items: center" >
      <el-pagination
          style="margin: 0 auto"
          :current-page="pagination.current"
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.pageSize"
          @currentChange="pageChange"
      />
      </div>
    </div>
    </el-affix>
    <el-dialog
        v-model="dialogBuy"
        width="500"
        :show-close="false"
    >
      <template #default>
        <div class="icc-box" v-if="payInfo&&payInfo.waybill_sn">
          <img src="@/assets/success.png" class="icc" alt="">
          <h1>兑换成功!</h1>
          <p class="d">订单号：{{ payInfo.log.order_sn }}</p>
          <a-divider style="border-color: rgba(0,0,0,0.09)" dashed/>
          <div class="l-box">
            <div class="l">兑换日期</div>
            <div class="l">兑换数量</div>
          </div>
          <div class="l-box">
            <div class="n">{{ dayjs(payInfo.log.createtime * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            <div class="n">{{ payInfo.log.score }}</div>
          </div>
          <div style="width: 5px;height: 30px"></div>

          <div class="l-box">
            <div class="l">兑换状态</div>
            <div class="l">消耗兑换币</div>
          </div>
          <div class="l-box">
            <div class="n">
              <a-tag color="green" style="transform: scale(1.2)">成功</a-tag>
            </div>
            <div class="n">
              <a-tag color="green" style="transform: scale(1.2)">{{ payInfo.log.score }}</a-tag>
            </div>
          </div>
          <a-divider style="border-color: rgba(0,0,0,0.09)" dashed/>
          <h1>单号列表</h1>
          <div v-html="payInfo.waybill_sn" style="padding: 10px;width: 440px;height: 115px;border-radius: 5px;box-sizing:border-box;overflow-x: hidden;overflow-y: scroll;border:1px solid #dcdfe6 " >

          </div>
          <el-button type="primary" style="width: 80%;height:40px;transform: translateY(50px)" @click="copyToClipboard">
            复制单号
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>

</template>

<style lang="less" scoped>
.o-list-box {
  display: flex;
  //height: 100%;
  flex: 1;
  flex-direction: column;
}

.tab-box::-webkit-scrollbar {
  width: 0px;
  overflow-y: hidden;
}

.tab-box {
  width: 100%;
  flex: 1;
//overflow-y: scroll;
}

.search-box {
  display: flex;
  flex-wrap: wrap;

  .search-item-box {
    margin-right: 20px;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }
}

.b {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  margin-bottom: 10px;

  .ba {
    display: flex;
    justify-content: flex-begin;
    padding: 5px;
    margin-bottom: 10px;
  }
}

.icc-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-80px);

  .icc {
    width: 100px;
    background: white;
    border-radius: 50%;
  }
}

.l-box {
  display: flex;
  justify-content: space-between;
  width: 90%;

  .l {
    color: rgba(0, 0, 0, 0.5);
  }

  .n {
    color: black;
    font-size: 18px;
    margin-top: 8px;
  }
}
/deep/.el-select__wrapper{
  min-height: 40px !important;
}
/deep/thead{
  color: rgb(61, 118, 206) !important;


}
/deep/th{
  background: rgb(235, 243, 255) !important;
}

/deep/ .number:nth-last-child(1){
  //background: rebeccapurple !important;
  display: none;
}
</style>